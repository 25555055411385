import React, { useRef, useState, useEffect } from "react"
import contactInfo from "../../site/settings/contact_info.json"
import styled from "styled-components"

import ArrowRight from "./arrowRight"

const StyledSection = styled.section`
  background-color: var(--bg-black);
  color: var(--white);
  margin-top: 6rem;
  padding: 4rem 0 2rem;

  h2 {
    font-size: 3.5rem;
    letter-spacing: 0.55rem;
    line-height: 110%;
    margin: 0 0 3rem;
    text-transform: none;
  }

  .use-form-button {
    display: none;
  }

  address {
    padding-bottom: 2rem;
  }

  @media only screen and (min-width: 62em) {
    h2 {
      font-size: 5.5rem;
      margin: 0 0 9.5rem;
    }

    .use-form-button {
      background-color: inherit;
      border: none;
      outline: none;
      color: var(--white);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 5rem;
    }

    margin-top: 20rem;
    min-height: calc(100vh - var(--footer-height));
    display: flex;
    align-items: center;
    justify-content: stretch;
  }
`

const StyledForm = styled.form`
  background-color: var(--white);
  color: var(--black);
  padding: 2.5rem 5rem;
  min-height: ${({ formWidth }) => formWidth}px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;

  h3 {
    font-size: 2.8rem;
    font-weight: 600;
    margin-bottom: 1rem;
    letter-spacing: 0.06rem;
  }

  input,
  textarea {
    width: 100%;
    border: 1px solid var(--black);
    padding: 1.4rem;
    font-weight: 300;
    margin-top: 0.5rem;
    font-size: 1.8rem;
    line-height: 2rem;

    &::placeholder {
      opacity: 0.7;
      font-family: var(--fontFamily-titles);
      text-transform: uppercase;
      font-size: 1.3rem;
      line-height: 2rem;
    }

    &:focus {
      border-width: 2px;
      border-radius: 0;
      outline: none;
    }
  }

  textarea {
    resize: none;
  }

  p {
    margin-top: 1rem;
    font-weight: 500;
  }
`

const ContactSection = () => {
  const formEl = useRef(null)
  const formInput = useRef(null)
  const [formWidth, setFormWidth] = useState(0)

  useEffect(() => {
    setFormWidth(formEl?.current?.clientWidth)
  }, [formEl])

  return (
    <StyledSection id="kontakt">
      <div className="container">
        <div className="row start">
          <div className="col-xs-12 col-md-6">
            <h2>
              Chcesz rozpocząć <br />z nami współpracę?
            </h2>
            <button
              type="button"
              className="use-form-button"
              onClick={() => formInput.current.focus()}
            >
              Skorzystaj z formularza <ArrowRight />{" "}
            </button>
            <address>
              <p>
                <span className="highlight">Zadzwoń: </span>{" "}
                <a href={`tel:${contactInfo.phone}`}>{contactInfo.phone}</a>
              </p>
              <p>
                <span className="highlight">Napisz: </span>{" "}
                <a href={`mailto:${contactInfo.email}`}>{contactInfo.email}</a>
              </p>
            </address>
          </div>
          <div className="col-xs-12 col-md-6 col-lg-5 col-lg-offset-1">
            <StyledForm
              className="col-xs-12"
              name="contact"
              method="POST"
              data-netlify="true"
              netlify-honeypot="bot-field"
              formWidth={formWidth}
              action="/dziekujemy/"
              ref={formEl}
            >
              <h3>Napisz wiadomość</h3>
              <input type="hidden" name="form-name" value="contact" />
              <p>
                <label>
                  Email nadawcy{" "}
                  <input
                    type="email"
                    name="email"
                    placeholder="Podaj swój email"
                    ref={formInput}
                    required
                  />
                </label>
              </p>
              <p>
                <label>
                  Wiadomość{" "}
                  <textarea
                    name="message"
                    rows="7"
                    placeholder="Wpisz wiadomość"
                    required
                  ></textarea>
                </label>
              </p>
              <p>
                <button className="button" type="submit">
                  <span>Wyślij</span>
                </button>
              </p>
            </StyledForm>
          </div>
        </div>
      </div>
    </StyledSection>
  )
}

export default ContactSection
