import React from "react"
import styled from "styled-components"

const ArrowRightIcon = () => (
  <StyledArrowRight
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    font-weight="bold"
  >
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="1"
      d="M19.354 10.146l-6-6c-0.195-0.195-0.512-0.195-0.707 0s-0.195 0.512 0 0.707l5.146 5.146h-16.293c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5h16.293l-5.146 5.146c-0.195 0.195-0.195 0.512 0 0.707 0.098 0.098 0.226 0.146 0.354 0.146s0.256-0.049 0.354-0.146l6-6c0.195-0.195 0.195-0.512 0-0.707z"
    ></path>
  </StyledArrowRight>
)

const StyledArrowRight = styled.svg`
  margin-left: 1rem;
`

const arrowRight = () => <ArrowRightIcon />

export default arrowRight
