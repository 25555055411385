import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

export const addImageScrollAnimation = (el, name, start = null) => {
  const startWindowAnimation =
    el.getBoundingClientRect().y < window.innerHeight
      ? window.innerHeight - el.getBoundingClientRect().y
      : null

  gsap.set([...el.querySelectorAll("img")], {
    transformOrigin: "center center",
  })
  gsap.fromTo(
    [...el.querySelectorAll("img")],
    {
      y: `${!start ? -25 : 0}`,
      opacity: 1,
    },
    {
      y: 0,
      //   scale: 1.1,
      opacity: 1,
      scrollTrigger: {
        id: `section-${name}-img`,
        scroller: ".scroll-container",
        trigger: el,
        start:
          start ||
          `top bottom-=${startWindowAnimation ? startWindowAnimation : 150}`,
        end: "bottom top",
        scrub: true,
        toggleActions: "play none none reverse",
        // markers: true
      },
    }
  )
}

export const setScrollAnimation = (
  localLocoScroll,
  sections = null,
  additionalAnimations = null
) => {
  localLocoScroll.on("scroll", ScrollTrigger.update)

  gsap.set('body', { maxHeight: "unset", overflow: 'auto' })

  ScrollTrigger.scrollerProxy(".scroll-container", {
    scrollTop(value) {
      return arguments.length
        ? localLocoScroll.scrollTo(value, 0, 0)
        : localLocoScroll.scroll.instance.scroll.y
    }, // we don't have to define a scrollLeft because we're only scrolling vertically.
    getBoundingClientRect() {
      return {
        top: 0,
        left: 0,
        width: window.innerWidth,
        height: window.innerHeight,
      }
    },
    // LocomotiveScroll handles things completely differently on mobile devices - it doesn't even transform the container at all! So to get the correct behavior and avoid jitters, we should pin things with position: fixed on mobile. We sense it by checking to see if there's a transform applied to the container (the LocomotiveScroll-controlled element).
    pinType: document.querySelector(".scroll-container").style.transform
      ? "transform"
      : "fixed",
  })

  additionalAnimations && additionalAnimations()

  sections &&
    sections.current.forEach((el, index) => {
      // gsap.fromTo(
      //   el.querySelector("img"),
      //   { y: -20 },
      //   {
      //     y: 20,
      //     scrollTrigger: {
      //       id: `section-${index}`,
      //       trigger: el,
      //       start: "top center+=100",
      //       // end: "end end",
      //       scrub: true,
      //       toggleActions: "play none none reverse",
      //       markers: true,
      //     },
      //   }
      // )
      // gsap.fromTo(
      //   el,
      //   { opacity: 0 },
      //   {
      //     opacity: 1,
      //     duration: 1,
      //     scrollTrigger: {
      //       id: `section-${index}`,
      //       scroller: ".scroll-container",
      //       trigger: el,
      //       start: "top bottom-=200",
      //       end: "bottom top+=200",
      //       scrub: false,
      //       // end: "bottom bottom",
      //       toggleActions: "play reverse play reverse",
      //       markers: true,
      //     },
      //   }
      // )

      addImageScrollAnimation(el, index)
      // gsap.fromTo(
      //   [...el.querySelectorAll("img")],
      //   {
      //     y: 0,
      //     scale: 1,
      //     opacity: 1,
      //   },
      //   {
      //     y: 30,
      //     scale: 1.02,
      //     opacity: 1,
      //     scrollTrigger: {
      //       id: `section-${index}-img`,
      //       scroller: ".scroll-container",
      //       trigger: el,
      //       start: "top bottom-=150",
      //       end: "bottom top",
      //       scrub: true,
      //       toggleActions: "play none none reverse",
      //       markers: true,
      //     },
      //   }
      // )

      // ScrollTrigger.create({
      //   animation: sectionTl,
      // })
    })

  const updateLocoScroll = () => localLocoScroll.update()
  // each time the window updates, we should refresh ScrollTrigger and then update LocomotiveScroll.
  ScrollTrigger.addEventListener("refresh", updateLocoScroll)
  // after everything is set up, refresh() ScrollTrigger and update LocomotiveScroll because padding may have been added for pinning, etc.
  ScrollTrigger.refresh()

  return () => {
    ScrollTrigger.removeEventListener("refresh", updateLocoScroll)
  }
}
